import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
    }, {
      path: '/contact',
      name: 'Contact',
      component: () => import("@/views/Contact.vue"),
      meta: {
        requiresAuth: true
      },
      children: []
    }, {
      // !!! ALWAYS LAST ROUTE
      path: "*",
      name: "NotFound",
      component: () => import("@/views/NotFound.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
