<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->

    <!-- <h2>Under constructionara!</h2> -->
    <!-- <Loading /> -->
    <Opening />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Opening from '@/components/Opening.vue'
// import Loading from '../components/Loading.vue'

export default {
  name: 'Home',
  components: {
    // HelloWorld
    Opening
    // Loading
  }
}
</script>

<style lang="scss">
$logo_block_width: 100px;
$logo_m_height: 200px;
$logo_h_height: 300px;

/* Using SCSS variables to store breakpoints */
$breakpoint-tablet: 768px;
@media (min-width: $breakpoint-tablet) {

}
</style>
