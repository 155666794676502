<template>
  <div>
    <div id="opening" class="d-flex justify-center align-center">
        <div class="background_container">
          <div class="red_block lb"></div>
          <div class="red_block lt"></div>
          <div class="red_block rt"></div>
          <div class="red_block rb"></div>

          <div class="letters_container">
            <div class="block short m1"></div>
            <div class="block short m2"></div>
            <div class="block short m3"></div>
            <div class="block h1"></div>
            <div class="block short h2"></div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
$dimension_red_block: 100px;
$border_radius_red_block: $dimension_red_block;
$width_block: $dimension_red_block / 3;
$height_block: $dimension_red_block / 3 * 2;
$height_h_block: $height_block * 1.5;
$margin_letters: $dimension_red_block / 50;
$border_radius_block: $dimension_red_block / 3;

$showDuration: 1.6s;
$moveDuration: 0.5s;
$toRoundDuration: 0.8s;
$showDelay: 1s;
$showDelayDetail: 0.2s;
$moveDelay: $showDelay + ($showDelayDetail * 5) + 0.2s; // All move at the same time so delay the same as well
$toRoundDelay: $moveDelay + $moveDuration + 0.2s; // All round at the same time so delay the same as well

#opening {
  height: calc(100vh - #{$dimension_red_block});
}

.background_container {
  display: block;
  position: relative;
  width: $dimension_red_block * 2;
  height: $dimension_red_block * 2;
  // top: $dimension_red_block;
  // top: -$dimension_red_block;
  // left: calc(50% - ($dimension_red_block * 2));
}

.letters_container {
  display: block;
  position: relative;
  width: $width_block * 6;
  height: $height_block * 2;
  margin-left: auto;
  margin-right: auto;
  left: (($dimension_red_block * 2) - (($width_block * 5) + ($margin_letters * 4))) / 2;
  // top: (($dimension_red_block * 2) - $height_h_block) - ($dimension_red_block / 2);
  // top: (($dimension_red_block * 2) - $height_h_block) / 2;
  top: ($dimension_red_block - ($height_h_block / 2));
}

.red_block {
  background-color: #EC0000;
  display: block;
  position: absolute;
  opacity: 0;
  width: $dimension_red_block;
  height: $dimension_red_block;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-duration: $showDuration, ($toRoundDuration / 2);

  &.lb {
    top: $dimension_red_block;
    animation-name: show, toRoundLB;
    animation-delay: $showDelayDetail, $toRoundDelay;
  }
  &.lt {
    animation-name: show, toRoundLT;
    animation-delay: $showDelayDetail, $toRoundDelay;
  }
  &.rt {
    left: $dimension_red_block;
    animation-name: show, toRoundRT;
    animation-delay: $showDelayDetail * 2, $toRoundDelay;
  }
  &.rb {
    left: $dimension_red_block;
    top: $dimension_red_block;
    animation-name: show, toRoundRB;
    animation-delay: $showDelayDetail * 3, $toRoundDelay;
  }
}

.block {
  display: inline-block;
  width: $width_block;
  height: $height_block;
  margin-left: $margin_letters;
  opacity: 0;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  background-color: white;

  &.short {
    transform: translateY(-($dimension_red_block / 3));
    animation-name: show, moveDown, toRound;
    animation-duration: $showDuration, $moveDuration, $toRoundDuration;
    transition: 0.5s;

    &:hover {
      height: $dimension_red_block * 2;
      cursor: pointer;
    }
  }

  &.m1 {
    // background-color: #CCC;
    animation-delay: $showDelay, $moveDelay, $toRoundDelay;

  }
  &.m2 {
    // background-color: #999;
    animation-delay: $showDelay + $showDelayDetail, $moveDelay, $toRoundDelay;
  }
  &.m3 {
    // background-color: #666;
    animation-delay: $showDelay + ($showDelayDetail * 2), $moveDelay, $toRoundDelay;
  }
  &.h1 {
    // background-color: #333;
    height: $height_h_block;
    animation-name: show, toRound;
    animation-duration: $showDuration, $toRoundDuration;
    animation-delay: $showDelay + ($showDelayDetail * 3), $toRoundDelay;
  }
  &.h2 {
    // background-color: #000;
    animation-delay: $showDelay + ($showDelayDetail * 4), $moveDelay, $toRoundDelay;
  }
}

// @keyframes show {
//   0% {opacity: 0;}
//   90% {opacity: 0.8;}
//   100% {opacity: 1;}
// }
@keyframes show {
  from {opacity: 0;}
  to {opacity: 1;}
}
@keyframes moveDown {
  from {transform: translateY(-($dimension_red_block / 3));}
  to {transform: translateY(0px);}
}
@keyframes toRound {
  from {border-radius: 0;}
  to {border-radius: $border_radius_block;}
}
@keyframes toRoundLB {
  from {border-radius: 0;}
  to {border-radius: 0 0 0 $border_radius_red_block;}
}
@keyframes toRoundLT {
  from {border-radius: 0;}
  to {border-radius: $border_radius_red_block 0 0 0;}
}
@keyframes toRoundRT {
  from {border-radius: 0;}
  to {border-radius: 0 $border_radius_red_block 0 0;}
}
@keyframes toRoundRB {
  from {border-radius: 0;}
  to {border-radius: 0 0 $border_radius_red_block 0;}
}
</style>
