<template>
    <!-- <v-app-bar app color="white"> -->
      <!-- <router-link to="/"
        ><v-img
          :src="require('../assets/logo-amsterdam_colors-circle-301.png')"
          class="headerLogo"
          contain
          height="50"
          width="50"
      /></router-link> -->
      <!-- <div id="nav">
        <router-link to="/">Home</router-link>
        <router-link to="/about">
          About
        </router-link>
        <router-link to="/contact">
          Contact
        </router-link>
      </div> -->
    <!-- </v-app-bar> -->
    <div class="btn-theme">
      <v-btn @click="toggleTheme" text rounded>Toggle Theme</v-btn>
    </div>
</template>

<script>

export default {
  name: "Header",
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
  }
}
</script>

<style lang="scss">
.btn-theme {
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;
}
.headerLogo {
  max-width: 20px;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
    opacity: 0.7;
    margin-left: 10px;
    text-decoration: none;

    &.router-link-exact-active {
      opacity: 1;
      color: #42b983;

      &:hover {
        text-decoration: none;
        cursor: default;
      }
    }
    &:hover {
      opacity: 1;
      text-decoration: underline;
    }
  }
}
</style>
