<template>
  <v-app>
    <v-main >
      <Header />
      <v-container>
        <transition name="fadeUp" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-container>
      <div class="message" unselectable="on">
        Under construction
      </div>
    </v-main>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
import Header from './components/Header';

export default {
  name: 'App',

  components: {
    // HelloWorld,
    Header
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
.message {
  display: block;
  position: absolute;
  bottom: 10%;
  width: 100%;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
</style>
